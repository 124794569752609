import loadable from '@loadable/component'
import LargeForm from 'components/forms/LargeForm'
import SectionTitle from 'components/global/SectionTitle'
import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Modal from '../components/global/Modal'
import OverlayVideo from '../components/overlay-video'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

const PDFViewer = loadable(() => import('../components/arc-core/PDFViewer'))

interface ArrowProps {
	left?: boolean
	right?: boolean
	onClick?: () => void
}

const CustomArrow = ({ left, right, onClick }: ArrowProps) => {
	return (
		<button
			// @ts-ignore
			onClick={() => onClick()}
			className="absolute z-50 flex h-12 w-12 items-center justify-center rounded-full bg-black bg-opacity-50 transition-all duration-200 ease-in-out hover:bg-opacity-75"
			type="button"
			style={{ right: right ? 0 : '', left: left ? 0 : '' }}
		>
			{left && (
				<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
				</svg>
			)}
			{right && (
				<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
				</svg>
			)}
		</button>
	)
}

export default function StudentBooksPage() {
	const data = useStaticQuery<StudentBookQuery>(query).allContentfulStudentBook.edges

	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const [book, setBook] = useState<StudentBookQueryNode | null>(null)
	// const [theme] = useState('all')
	// const [themes] = useState(reduceThemes())

	const handleShow = (book: StudentBookQueryNode) => {
		setShow(true)
		setBook(book)
	}

	// function reduceThemes() {
	// 	let allThemes: string[] = []
	// 	data.forEach((theme) => allThemes.push(theme.node.theme.name))
	// 	const newThemes = [...new Set(allThemes)]
	// 	return newThemes
	// }

	// let themeFilter: StudentBookQueryNode[]
	// if (theme === 'all') themeFilter = data.filter((book) => book.node.theme.name !== 'all')
	// else themeFilter = data.filter((book) => book.node.theme.name === theme)

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			slidesToSlide: 4 // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 3 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
			slidesToSlide: 2 // optional, default to 1.
		}
	}

	return (
		<Layout pageName="Student Books">
			<Section>
				<Row>
					<div className="absolute top-0 left-0 z-auto h-full w-full bg-cover bg-no-repeat opacity-90" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
						<div className="white-transparent-gradient-strong h-full w-full"></div>
					</div>
				</Row>
				<Row center className="relative z-auto">
					<Col width="w-full" className="text-center">
						<img src="/images/student-books/sea-turtles-spread.png" className="mx-auto max-h-[600px]" />

						<H1 className="mb-3">Student-Authored Books</H1>
						<P className="mx-auto max-w-4xl">
							With{' '}
							<Link to="/arc-core">
								ARC Core<sup>®</sup>
							</Link>
							{',  '}
							students develop expertise as readers, writers, and researchers. Each knowledge-building unit culminates in a student-authored written product. Students
							have the opportunity to submit their books for publication by ARC Press<sup>®</sup>. The best student-authored books are included in our collections as
							well as available on <a href="https://www.americanreadingathome.com/bookstore/student-authors/"> ARC at Home</a>.
						</P>
					</Col>
				</Row>
			</Section>
			<Section>
				<div className="mb-40">
					<Carousel
						swipeable
						draggable={false}
						showDots={false}
						responsive={responsive}
						infinite={false}
						autoPlay={false}
						autoPlaySpeed={1000}
						keyBoardControl
						shouldResetAutoplay={false}
						renderButtonGroupOutside
						transitionDuration={500}
						removeArrowOnDeviceType={['tablet', 'mobile']}
						itemClass="px-5"
						sliderClass="items-end"
						containerClass="pb-10"
						customLeftArrow={<CustomArrow left />}
						customRightArrow={<CustomArrow right />}
					>
						{data
							.filter((b) => !b.node.purchaseLink)
							.map((b) => {
								return (
									<div key={b.node.id}>
										<button onClick={() => handleShow(b)} className="relative focus:outline-none">
											<GatsbyImage image={b.node.cover.gatsbyImageData} alt={b.node.cover.title} className="shadow-lg" />
											{/* <p className="text-sm text-gray-800 mt-4">{b.node.title}</p> */}
										</button>
									</div>
								)
							})}
					</Carousel>
				</div>
				<SectionTitle
					title="Student Books Published by ARC Press"
					text="ARC publishes the work of exceptional student authors as a trade-quality children’s book. These books are included in classroom libraries and are available on ARC at Home."
				/>
				<Carousel
					swipeable
					draggable={false}
					showDots={false}
					responsive={responsive}
					infinite={false}
					autoPlay={false}
					autoPlaySpeed={1000}
					keyBoardControl
					shouldResetAutoplay={false}
					renderButtonGroupOutside
					transitionDuration={500}
					removeArrowOnDeviceType={['tablet', 'mobile']}
					itemClass="px-5"
					sliderClass="items-end"
					containerClass="py-10"
					customLeftArrow={<CustomArrow left />}
					customRightArrow={<CustomArrow right />}
				>
					{data
						.filter((b) => b.node.purchaseLink)
						.map((b) => {
							return (
								<div key={b.node.id}>
									<button onClick={() => handleShow(b)} className="relative focus:outline-none">
										<GatsbyImage image={b.node.cover.gatsbyImageData} alt={b.node.cover.title} className="shadow-lg" />
									</button>
								</div>
							)
						})}
				</Carousel>
			</Section>
			<Section margin="my-20">
				<SectionTitle
					title="Meet the Authors"
					text="Learn more about how ARC Core’s gradual-release model creates independent, engaged learners whose Final Projects showcase both content knowledge and ELA skills."
				/>
				<Row className="items-center">
					<Col width="w-full md:w-1/2" className="mx-auto mb-6 md:mb-0">
						<OverlayVideo title="Bloodsuckers" />
					</Col>
					<Col width="w-full md:w-1/2" className="mx-auto">
						<OverlayVideo title="Providence 5th Grade Author Steps into Literary Adventure" />
					</Col>
				</Row>
			</Section>
			<Section margin="my-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="Student Books" />
					</Col>
				</Row>
			</Section>
			{book && (
				<Modal title={`${book.node.title} by ${book.node.author}`} isOpen={show} handleClose={handleClose} wide>
					{book.node.purchaseLink && (
						<div className="mb-6 flex justify-center">
							<a href={book.node.purchaseLink} target="_blank" rel="noreferrer">
								<Button variant="secondary" size="lg">
									Available on ARC at Home
								</Button>
							</a>
						</div>
					)}
					<PDFViewer pdf={book.node.book.file.url} />
				</Modal>
			)}
		</Layout>
	)
}

interface StudentBook {
	id: string
	title: string
	cover: {
		title: string
		gatsbyImageData: IGatsbyImageData
	}
	book: {
		file: {
			url: string
		}
		title: string
	}
	author: string
	published: boolean
	purchaseLink: string
	theme: {
		name: string
		slug: string
	}
}

interface StudentBookQueryNode {
	node: StudentBook
}

interface StudentBookQuery {
	allContentfulStudentBook: {
		edges: StudentBookQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulStudentBook(sort: { fields: [theme___name], order: ASC }) {
			edges {
				node {
					id
					title
					cover {
						title
						gatsbyImageData
					}
					book {
						file {
							url
						}
						title
					}
					author
					published
					purchaseLink
					theme {
						name
						slug
					}
				}
			}
		}
	}
`
